<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            EL Taskforce
          </h2> -->

          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:240px"
          />
        </span>

        </b-link>

        <b-card-text class="mb-2 text-center font-weight-bolder h4" style="color: #d0a837">
          Admin Login
        </b-card-text>

        <b-card-title class="mb-1" style="font-size: 20px; text-align: center">
          Welcome to EL Taskforce Order Management!
        </b-card-title>

        <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-0"
          >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <!-- form -->
        
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

           <!-- Username -->
            <b-form-group
              label="Username"
            >
              
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-username"
                  
                  placeholder="Username"
                  autofocus
                />
                
            </b-form-group>

            <!-- password -->
            <b-form-group label="Password">
              <div class="d-flex justify-content-between">
                <!-- <b-link :to="{}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              
                <b-input-group
                  class="input-group-merge"
                  
                >
                  <b-form-input
                    id="password"
                    v-model="password"

                    class="form-control-merge"
                    
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
               
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="warning"
              type="submit"
              block
              
            >
              Sign in
            </b-button>
          </b-form>
        

        <b-card-text class="text-center mt-2">
          <!-- <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link> -->
        </b-card-text>

        <div class="divider my-2">
          <!-- <div class="divider-text">
            or
          </div> -->
        </div>

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>

import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert,BImg
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { LOGIN } from "../store/actions.type"

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BAlert,
    BImg
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      showDismissibleAlert:false,
      error_message:'',
      appLogoImage : require('@/assets/images/logo/task-force-full.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    login() {
      this.$store.dispatch(LOGIN, {
                        data:{
                            username: this.username,
                            password:this.password,
                            account_type:['Admin']
                        },
                        api:'/api/login'
                    })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
              
              var userData = this.$store.getters.currentUser;
              
              userData.ability = [{
                action: "manage", subject: "all"
              }];

              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Login Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    //this.$router.push({ name:'admin-dashboard'});
                    window.location.replace('/admin/manage-order');
                });
            }       
        }); 
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
